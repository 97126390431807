<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
      <!-- 위험성 평가 목록 -->
      <c-table
        ref="table"
        title="LBL0002368"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="tabParam.riskAssessList"
        :filtering="false"
        :isExcelDown="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="editable"
        rowKey="ramRiskAssessmentPlanId"
        selection="multiple"
        @linkClick="linkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addRisk" />
            <!-- 제외 -->
            <c-btn v-if="editable && !disabled && tabParam.riskAssessList.length > 0" :showLoading="false"  label="LBLREMOVE" icon="remove" @btnClicked="removeRisk" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'accident-risk',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
        iimAccidentId: ''
      }),
    },
    tabParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        iimAccidentId: '',
        accidentStatusCd: '',
        riskAssessList: [],
      }),
    },
  },
  data() { 
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: "ramTechniqueName",
            field: 'ramTechniqueName',
            // 평가분류
            label: 'LBL0002369',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            // 평가명
            label: 'LBL0000367',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'assessmentDate',
            field: 'assessmentDate',
            // 평가기간
            label: 'LBL0000607',
            align: 'center',
            style: 'width:150px',
            sortable: true
          },
          {
            name: 'ramAssessTypeName',
            field: 'ramAssessTypeName',
            // 평가구분
            label: 'LBL0000608',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          // {
          //   name: 'regUserName',
          //   field: 'regUserName',
          //   // 등록자
          //   label: 'LBLREGISTER',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
        ],
        data: [],
        height: '280px'
      }, 
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      getUrl: '',
      saveUrl: '',
      deleteUrl: '',
      saveType: 'POST',
      isSave: false,
      linkRow: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.tabParam.accidentStatusCd) && this.tabParam.accidentStatusCd === 'ISPC000005'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.iim.accident.process.riskAssess.save.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.process.riskAssess.delete.url;
    },
    /* eslint-disable no-unused-vars */ 
    addRisk() {
      this.popupOptions.title = 'LBL0002370'; // 위험성 평가 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/processAccidentRiskPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRiskPopup;
    },
    closeRiskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveAssessRisks = [];
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.riskAssessList, {
            ramRiskAssessmentPlanId:_item.ramRiskAssessmentPlanId,
          });
          if (index === -1) {
            saveAssessRisks.splice(0, 0, {
              iimAccidentId: this.tabParam.iimAccidentId,
              ramRiskAssessmentPlanId: _item.ramRiskAssessmentPlanId,
              ramTechniqueCd: _item.ramTechniqueCd,
              ramTechniqueName: _item.ramTechniqueName,
              assessmentName: _item.assessmentName,
              ramStepName: _item.ramStepName,
              assessmentDate:_item.assessmentDate,
              ramAssessTypeName:_item.ramAssessTypeName,
              regUserName:_item.regUserName,
              reviewUserName:_item.reviewUserName,
              approvalUserName:_item.approvalUserName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
          }
        })
        this.$http.url = this.saveUrl;
        this.$http.type = 'POST';
        this.$http.param = saveAssessRisks;
        this.$http.request((_result) => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$emit('getDetail')
        },);
      }
    },
    removeRisk() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
         window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
            this.$_.forEach(selectData, _item => {
                this.tabParam.riskAssessList = this.$_.reject(this.tabParam.riskAssessList , _item)
                this.$refs['table'].selected = [];
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              },);
            })
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveAccident() {
      this.saveUrl = this.updateUrl;
      this.saveType = 'PUT';
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if(this.popupParam.iimAccidentId) {
            this.$set(this.save, 'iimAccidentId',  this.tabParam.iimAccidentId);
            this.$set(this.save, 'plantCd',  this.tabParam.plantCd);
            this.$set(this.save, 'accidentStatusCd',  this.tabParam.accidentStatusCd);
          }
          this.tabParam.regUserId = this.$store.getters.user.userId
          this.tabParam.chgUserId = this.$store.getters.user.userId
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    linkClick(row) {
      // this.$set(this.$data, 'linkRow', row)
      // this.$_.extend(this.popupOptions, this.popupOptionInfo)
      this.popupOptions.title = 'LBL0002371'; // 위험성평가 상세
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: row ? row.ramRiskAssessmentPlanId : '',
        ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        vendorFlag: row ? (row.vendorFlag > 0) : false,
        ramTechniqueCd: row.ramTechniqueCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
